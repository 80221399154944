import React, { useState, useEffect } from 'react';
import './App.css';

const CartaAmor = () => {
  const [diasJuntos, setDiasJuntos] = useState(0);
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    const fechaInicio = new Date(2024, 7, 15); // 15 de agosto
    const hoy = new Date();
    const diferencia = hoy - fechaInicio;
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    setDiasJuntos(dias);

    // Simular tiempo de carga
    const timer = setTimeout(() => {
      setCargando(false);
    }, 3000); // 3 segundos

    return () => clearTimeout(timer); // Limpiar el temporizador al desmontar el componente
  }, []); // Asegúrate de que esta línea esté así

  if (cargando) {
    return (
      <div className="cargando-container">
        <div className="spinner"></div>
      </div>
    );
}

  return (
    <div className="carta-container">
      <h1 className="carta-titulo">hola, te amo 😔</h1>
      <p className="carta-cuerpo">
        Hay algo que llevo guardado desde hace tiempo y siento que ya no puedo seguir callando.
        Quiero pedirte perdón. Perdón por cada vez que te hice sentir mal, por cada palabra que
        dije sin pensar, por cada momento en el que no estuve a la altura de lo que merecías.
        Sé que a veces me equivoco, y que muchas de esas veces, sin darme cuenta, te lastimé.
      </p>
      <p className="carta-cuerpo">
        Vos merecés lo mejor, y aunque he fallado en ocasiones, quiero que sepas que nunca fue mi
        intención herirte. A veces, en mi propia torpeza, no supe cómo cuidarte, cómo mostrarte
        que te amo, y te hice daño. No puedo cambiar el pasado, pero lo que sí puedo hacer es
        aprender de mis errores y seguir adelante con el compromiso de ser una mejor persona
        para vos.
      </p>
      <p className="carta-cuerpo">
        Me prometo, a vos y a mí, que voy a estar acá, a tu lado, siempre. No importa lo que
        pase, no importa cuán difíciles sean los días, voy a luchar por vos, por nosotros.
        Porque si hay algo que tengo claro, es que quiero caminar este camino con vos, quiero
        ser quien te apoye, quien te escuche, quien te haga sonreír cuando el mundo parece
        estar en contra.
      </p>
      <p className="carta-cuerpo">
        Cada día que pasa, aprendo algo nuevo de esta relación. Aprendo que el amor no siempre
        es fácil, que no siempre somos perfectos, pero también aprendo que vale la pena
        esforzarse. Vale la pena cada disculpa, cada intento de mejora, cada momento en el que
        decido ser más consciente de lo que hacés por mí y por nosotros.
      </p>
      <p className="carta-cuerpo">
        Quiero que sepas que, aunque no siempre lo diga con las palabras correctas o en el
        momento adecuado, te amo. Y te amo con cada parte de mí, incluso con las partes que
        aún estoy aprendiendo a mejorar. No soy perfecto, lo sé, pero quiero ser la mejor
        versión de mí mismo para vos.
      </p>
      <p className="carta-cuerpo">
        Llevamos <span className="carta-dias">{diasJuntos}</span> días juntos, y aunque ha habido
        altibajos, cada día que pasamos juntos reafirma lo importante que sos para mí. Quiero
        seguir aprendiendo, creciendo, y estando a tu lado. Porque vos sos la persona con
        la que quiero compartir mi vida.
      </p>
      <p className="carta-cuerpo">
        Perdón por los errores del pasado, y gracias por darme la oportunidad de seguir
        construyendo este futuro con vos. Siempre voy a estar acá, para vos, para nosotros.
      </p>
      <p className="carta-firma">💜 espero q lo veas pronto, te amo 💜</p>
    </div>
  );
};

export default CartaAmor;
